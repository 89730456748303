<template>
  <div class="hello">
        <div class="">



   <!-- Header start -->
    <header class="header">
        <div class="header__container flex-space">
            <a href="/" >
                <h1>vim.is</h1>
            </a>
            <nav class="nav">
                <ul class="nav__menu">
                    <li>
                        <a class="nav__menu--item btn btn__default" href="/login">Log in</a>
                    </li>
                </ul>
            </nav>
            <nav class="mobile-nav">
                <ul class="nav__menu">
                    <li>
                        <a class="nav__menu--item" href="/login">Log in</a>
                    </li>
                </ul>
            </nav>
        </div>
    </header>
    <!-- Header end -->
    <main>
    <!-- Hero start -->
        <section>
            <div class="hero pad-m">
                <div class="container">    
                    <div class="hero-content m-a">
                        <br><br><br><br>
                        <h1 class="hero-content__title mb-m">Learn Vim fast</h1>
                        <h2 class="subtitle">Practice your vim skills with these fun exercises. 
                        </h2>
                        <br><br>
                        <div class="hero-cta">
                            <a href="#exercise" class="btn btn_try">
                                Try an Exercise
                            </a>
                        </div>
                        <br>
                        <a href="https://www.producthunt.com/posts/master-vim?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-master-vim" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=294684&theme=light" alt="Master Vim - Learn Vim with interactive exercises | Product Hunt" style="width: 250px; height: 54px;" width="250" height="54" /></a>  
                  </div>
                </div>
            </div>
                <div class="hero-screen">
                    <div class="hero-screen__bg">
                        <img src="assets/hero_bg_desktop.svg" alt=""/>
                    </div>
                    <div class="hero-screen__image m-a">
                        <img  src='assets/screen-mockups.svg' alt=""/>
                    </div>
                </div>
        </section>
    <!-- Hero end -->
    <!-- Features start -->
    <section id="features" class="pad-l">
        <div class="container m-a">
            <div class="features-content m-a text-center mb-l">
                <h2 class="section-title mb-m">Master vim, it's easy!</h2>
                 <p class="section-paragraph">&nbsp;<br>
                </p> 
            </div>
            <div class="features-list">
                <div class="feature-item">
                    <div class="icon">
                        <a href="#exercise">
                            <img src="Icons-1.png" alt="vim" width="64px">
</a>
                    </div>
                    <span class="feature-item__title">Learn to use vim</span>
                    <p class="feature-item__paragraph">
                    Vim is a powerful text editor that can make you more productive and efficient.
                    </p>
                </div>
                <div class="feature-item ">
                    <div class="icon">
                        <a href="#exercise">
                            <img src="Icons-2.png" alt="exercises" width="64px">
</a>
                    </div>
                    <span class="feature-item__title">Easy Exercises</span>
                    <p class="feature-item__paragraph">
                    Once you get the hang of it, you’ll wonder how you ever lived without it.
                    </p>
                </div>                
                <div class="feature-item ">
                    <div class="icon">
                        <a href="#exercise">
                            <img src="Icons-3.png" alt="increase productivity" width="64px">
                        </a>
                    </div>
                    <span class="feature-item__title">Increase Productivity</span>
                    <p class="feature-item__paragraph">
                    You can use vim mode inside the terminal, SSH, Jetbrains IDE, Visual Studio Code and many more 
                    </p>
                </div>
            </div>
        </div>
    </section>
    <!-- Features end -->
    <!-- Testimonials start -->
    <section id="testimonials" class="pad-l">
        <div class="container">
            <div class="text-center mb-l">
                <a name="exercise"></a>
                <h2 class="section-title"> {{this.data[this.index].title}}</h2>
            </div>
            <div>
            In VIM, press <kbd class="kbc-button">ESC</kbd> to enter commands.<br>You can use the <kbd class="kbc-button">h</kbd> and <kbd class="kbc-button">l</kbd> keys to move the cursor.<br>Move on top of the <kbd class="kbc-button">%</kbd> character. Then press the <kbd class="kbc-button">x</kbd> key to remove it.<br>
            To undo press the <kbd class="kbc-button" >u</kbd> key.  
            <br></div>
        <br>
 <div id="parent">
            <VueCodeEditor @init="editorInit" theme="monokai" width="100%" id="editor" name="editor" v-on:input="onChange" v-model=answer ref="editor" :options="{
                fontSize: 18,
                highlightActiveLine: true,
                showLineNumbers: true,
                tabSize: 2,
            }" />>
  </div>
<br>
   <div class="col-lg-12">
            <div class="btn btn__primary">Exercise: <span class="score">{{this.index}} / {{this.data.length}} </span> </div>
            &nbsp;
            <div class="btn btn_reset" v-on:click="reset();">Reset</div>
        </div>
    
          
        </div>
    </section>
    <!-- Testimonials end -->
    <!-- Pricing start-->
    <section class="pad-l">
        <div class="container" id="pricing">
          
            <div class="pricing-content text-center">
                <h2 class="section-title mb-m">Learn Vim Fast.</h2>
                <p class="section-paragraph">It is time to go beyond the dry, boring standard tutorials. All-new interactive lessons to help you master vim faster.</p>
            </div>
            <div class="plans-list">
                <div class="plan-item">
                    <ul class="plan-item__features">
                        <li class="plan-item__features--item">
                            <ion-icon name="checkmark-outline"></ion-icon>
                            <span>Finally master vim through fun interactive exercises.</span>
                        </li>
                        <li class="plan-item__features--item">
                            <ion-icon name="checkmark-outline"></ion-icon>
                            <span>We made this website to help you learn everything that you need to know to start using vim.</span>
                        </li> 
                        <br>  
                        <b></b>
                        <li class="plan-item__features--item">
                            <ion-icon name="checkmark-outline"></ion-icon>
                            <span><i class="fa fa-check"></i> Learn through interactive exercises
                            </span>
                        </li>
                        <li class="plan-item__features--item">
                            <ion-icon name="checkmark-outline"></ion-icon>
                            <span><i class="fa fa-check"></i> Improve your coding speed
                            </span>
                        </li>

                        <li class="plan-item__features--item">
                            <ion-icon name="checkmark-outline"></ion-icon>
                            <span><i class="fa fa-check"></i> No setup, practice in your browser
                            </span>
                        </li>

                        <li class="plan-item__features--item">
                            <ion-icon name="checkmark-outline"></ion-icon>
                            <span><i class="fa fa-check"></i> Pay once, own it forever
                            </span>
                        </li>



                    </ul>
                    <div class="plan-item__action">
                        <a href="https://pythoncourses.gumroad.com/l/GRjBCw/u1lm0i3" class="btn btn__primary">Learn Vim</a>
                    </div>
                </div>
       
            </div>
        </div>
    </section>
    <!-- Pricing end-->
    </main>

    <!-- Testimonials start -->
    <section id="testimonials" class="pad-l">

            <div class="text-center mb-l">
                <h2 class="section-title"> Join others who love vim.is today!</h2>
            </div>

                <br><br>
            <div class="container">
<div class="testimonials-list">
                <div class="testimonial-item">
                    <div class="testimonial-item__avatar">
                        <img src="vivek.webp" alt="">
                    </div>
                    <span class="testimonial-item__name">Vivek</span>
                    <div class="testimonial-item__text">
                        <img src="quote.svg" alt="">
                        <blockquote>
                            I always wanted some interactive way of learning vim, as its very tough to read and learn it.
                        </blockquote>
                    </div>
                </div>
                <div class="testimonial-item">
                    <div class="testimonial-item__avatar">
                        <img src="andrew.webp" alt="">
                    </div>
                    <span class="testimonial-item__name">Andrew
                    </span>
                    <div class="testimonial-item__text">
                        <img src="quote.svg" alt="">
                        <blockquote>
                            Nice! I like the simple interface, makes it easy to focus on a single task and get the movement ingrained in my fingers :)
                        </blockquote>
                    </div>
                </div>

    <div class="testimonial-item">
                    <div class="testimonial-item__avatar">
                        <img src="zahn.jpeg" alt="">
                    </div>
                    <span class="testimonial-item__name">Zahn
                    </span>
                    <div class="testimonial-item__text">
                        <img src="quote.svg" alt="">
                        <blockquote>
                            This is really cool. It makes coding so much easier and much faster. I would recommend the vim editor to anyone who wants more control and efficiency in their coding.
                        </blockquote>
                    </div>
                </div>

              </div>
            </div>

    </section>

    <!-- Footer start -->
    <footer id="footer" class="">
        <div class="container">
            <div class="footer-content mb-l">
                <div class="footer-info">
                    <a href="#" class="footer-info__logo">
                        <img src="assets/logo-footer.svg" alt=""/>
                    </a>
                    <!-- <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus, minima vel excepturi fugit cumque autem repudiandae veritatis ex provident qui consequuntur molestias. Et similique excepturi accusamus at in quis eum?</p>
              -->  </div>
                <div class="footer-links">
               
              
                </div>
            </div>
            <div class="footer-bottom">
                <p class="footer-bottom__copyright">Copyright © 2021 Frank Anemaet - frank at fastmail dot org</p>
                <ul class="footer-bottom__social">
                    <li>
                        <a class="footer-bottom__social--item" href="#">
                            <ion-icon name="logo-twitter"></ion-icon>
                        </a>
                    </li>
                    <li>
                        <a  class="footer-bottom__social--item" href="#">
                            <ion-icon name="logo-facebook"></ion-icon>
                        </a>
                    </li>                    
                    <li>
                        <a  class="footer-bottom__social--item" href="#">
                            <ion-icon name="logo-linkedin"></ion-icon>                      
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </footer>
    <!-- Footer end -->  


   </div>
  </div>
  
</template>

<script>

window.ace.require = window.ace.acequire;
// import { Ace as AceEditor } from 'vue2-brace-editor';
import VueCodeEditor from 'vue2-code-editor';
 


export default {
  mounted() {
    const plugin = document.createElement("script");
    plugin.setAttribute(
      "src",
      "https://testimonial.to/js/iframeResizer.min.js"
    );
    plugin.async = true;
    document.head.appendChild(plugin);
  },
  components: {
        VueCodeEditor
  },
  name: 'HelloWorld',
  metaInfo: {
      title: 'Interactive Vim Exercises '    
  },
  props: {
    msg: String
  },
     created: function() {
        this.getData();

    },
      methods: {
        getData() {
            fetch('../landing.json')
                .then(response => response.json())
                .then(data => (this.data = data))
                .then(response => {
                    this.answer = this.data[this.index].placeholder;
                    console.log('fetched data', response);
                });

        },
               editorInit: function(editor) {
            require('brace/mode/html')
            require('brace/mode/python')
            require('brace/mode/less')
            require('brace/theme/chrome')
            require('brace/theme/dracula')
            require('brace/theme/monokai')
            editor.setOption('fontSize', '16pt')
            editor.setOption('vScrollBarAlwaysVisible', true)
            editor.setOption('theme', 'ace/theme/textmate')
            require('brace/keybinding/vim')
            editor.setKeyboardHandler('ace/keyboard/vim')

            //this.answer = this.placeholders[this.index];
        },
        editorRender: function(output) {
            require('brace/mode/html')
            require('brace/mode/python')
            require('brace/mode/less')
            require('brace/theme/chrome')
            require('brace/theme/chaos')
            require('brace/theme/monokai')
            output.setOption('fontSize', '16pt')
            output.setOption('vScrollBarAlwaysVisible', true)
            output.setOption('theme', 'ace/theme/textmate')
        },
        onChange: function() {
            this.correct();
        },
        reset: function() {
          console.log('reset');
          this.index = 0;
          this.getData();
          
        },
        correct: function() {
            //console.log(' answer   = ', this.answer, this.answer.length, typeof this.answer);
            //console.log(' solution = ', this.data[this.index].solution, this.index, this.data[this.index].solution.length, typeof this.data[this.index].solution);
            if (this.data[this.index].solution == this.answer) {
                //console.log(this.index);
                //console.log(this.data.length);
                
                if (this.index >= this.data.length - 1) {
                  console.log('completed');
                  this.reset();
                  document.getElementById("pricing").scrollIntoView();
                }
                this.oldIndex = 0;
                //console.log('correct');
                this.answer = '';
                this.score = this.score + 1;
                this.index = this.index + 1;
                //console.log('index is ', this.index);
                this.totalTime += this.elapsedTime;
                
                //this.answer = this.data[this.index].placeholder;

                setTimeout(() => {
                    this.answer = this.data[this.index].placeholder;
                }, 10)

            }
        },
      },
         data: function() {
        return {
            elapsedTime: 1,
            totalTime: 1,
            averageTime: 1,
            timer: undefined,
            answer: "",
            score: 0,
            index: 0,
            data: [{},{}],
            title: '',
            exercise: [],
        }
      },
}

</script>



<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


@import '../assets/hero/css2.css';
@import '../assets/hero/normalize.css';
@import '../assets/hero/style.css';
@import '../assets/hero/responsive.css';

.plan-item:nth-child(1), .plan-item:nth-child(3) {
  border: 2px solid rgba(0, 0, 0, 0.1);
}

#pricing {
  color:black;
}

.kbc-button {
    padding: 6px;
    margin-top: 10px;
    text-align: center;
}


@media screen and (min-width : 1024px){
.btn_try {
    margin-bottom: 16px;
    background: #026be6;
    color: #fff;
    width: 25%;
    padding-top: 16px;
    padding-bottom: 16px;
    border-radius: 6px;
}
}


@media screen and (max-width : 1024px){
.btn_try {
    margin-bottom: 16px;
    background: #026be6;
    color: #fff;
    padding-top: 16px;
    padding-bottom: 16px;
    border-radius: 6px;
}
}

.btn_reset {
  background: white;
opacity: 1;
font-size: 1.6rem;
cursor: pointer;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
padding: 0.8rem 2.6rem;
transition: transform 100ms ease-in-out;
border-radius: 3px;
color: var(--text-dark);
display: inline-block;
text-align: center;
border: 1px solid #dadce0;
color: #000; 
border: 1px solid black;
flex: 1 1 auto;
}

.feature-item__icon a {
 color:white;
}

.btn {
font-size: 1.125em;
font-family: 'Roboto', sans-serif;
letter-spacing: -0.025rem;
font-weight: 400;
font-family: 'Roboto', sans-serif;
}
.btn:hover {
  color:white;
}

h2.subtitle {
    color: #FFFFFF;
    font-family: 'Roboto', sans-serif;
    font-size: 1.125em;
    line-height: 1.625;
    font-weight: 300;
}

.subtitle {
 color: white;
}
   #parent {
     width: 100%;
     height: 400px;
       display:block;
       position:relative;
    }

  #editor {
        position: absolute; /* Added */
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: 0 auto;
    border-radius: 8px;
    }

.testimonial-item__avatar {
    height: 6rem;
    width: 6rem;
    position: absolute;
    top: -6.4rem;
    left: 50%;
    transform: translateX(-50%) translateY(50%);
    border-radius: 50%;
    border-top: 4px solid #4704a1;
    border-left: 4px solid #4704a1;
}

#testimonialto-vim-is-light {
    min-height: 400px;
}

.icon {
  padding-bottom: 10px;
}
</style>

